exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-boro-js": () => import("./../../../src/pages/boro.js" /* webpackChunkName: "component---src-pages-boro-js" */),
  "component---src-pages-grouch-js": () => import("./../../../src/pages/grouch.js" /* webpackChunkName: "component---src-pages-grouch-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ncga-js": () => import("./../../../src/pages/ncga.js" /* webpackChunkName: "component---src-pages-ncga-js" */),
  "component---src-pages-wakeforest-js": () => import("./../../../src/pages/wakeforest.js" /* webpackChunkName: "component---src-pages-wakeforest-js" */)
}

